<script setup>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
const props = defineProps({
  data: Object
})

const { sanitize } = useUtils();
const { getPulseCourseById } = useCourseData();
let productCards = sanitize(props.data.products.data)
let cards = []
let windowWidth = ref(0), enoughSlides
const splide = ref();

for (let i = 0; i < productCards.length; i++){
  cards.push(productCards[i].attributes)
}

// Carousel options
const options = {
  perPage: 2,
  perMove: 1,
  pagination: false,
  type: 'slide',
  speed: 500,
  autoWidth: true,
  arrows: false,
  gap: '20px',
  mediaQuery: 'min',
  breakpoints: { 
    1200: {
      arrows: true,
      perPage: 4,
      perMove: 4
    },
    1400: {
      arrows: true,
      perPage: 5,
      perMove: 5
    }
  }
}

// Window resizing event listener
onMounted(() => {
  windowWidth.value = window.innerWidth
  window.addEventListener('resize', function () {
    windowWidth.value = window.innerWidth
  })
})

const slugify = (string) => {
  return string.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
}

const cardFilters = (card) => {
  const topics = card.selectTopics.join(' ');
  const ages = [];
  for (let i = 0; i < card.selectAges.length; i++) {
    ages.push(slugify(card.selectAges[i]));
  }

  // Locations
  let locations = '';
  if (card.pulseCourseId !== null && card.pulseCourseId > 0 && card.locationIds !== null) {
    locations = card.locationIds.join(' ') + ' ipc';
  }

  return 'card-product-item ' + topics + ' ' + ages.join(' ') + ` ${locations}`;
}

// Exposing the splide reference to access it from the filter
defineExpose({
  splide
})


</script>

<template>
  <div class="block card-product">
    <div class="container">
      <div class="row" v-if="data.title !== null && data.title !== ''">
        <div class="col-12">
          <h3>{{ data.title }}</h3>
        </div>
      </div>
      <div  class="row justify-content-center">
        <div  class="col-12">
          <Splide :options="options" ref="splide">
            <SplideSlide v-for="(card, index) in cards" :key="index" :class="cardFilters(card)">
              <div class="slide_item" >
                <a :href="card.url" class="card-product-container" >
                  <NuxtImg :src="card.productImage.formats.small ? card.productImage.formats.small.url : card.productImage.formats.thumbnail.url" 
                    :title="card.productImageTitle"
                    :alt="card.productImage.alternativeText"
                    :class="'card-image' + (card.mobileProductImage ? ' d-none d-md-block' : '')" />
                  <NuxtImg v-if="card.mobileProductImage" :src="card.mobileProductImage.formats.small ? card.mobileProductImage.formats.small.url : card.mobileProductImage.formats.thumbnail.url" 
                    :title="card.mobileProductImageTitle ? card.mobileProductImageTitle : card.productImageTitle"
                    :alt="card.mobileProductImage.alternativeText"
                    class="card-image d-block d-md-none" />
                  <div class="card-details">
                    <div :class="'product-badge bg-' + card.badgeColor">
                      <span :class="'desktop-badge' + card.mobileProductBadgeText ? ' d-none d-md-inline' : ''" v-html="card.productBadgeText"></span>
                      <span v-if="card.mobileProductBadgeText" class="mobile-badge d-md-none">{{ card.mobileProductBadgeText }}</span>
                    </div>
                  </div>
                  <b v-html="card.productName" class="card-title"></b>
                  <article class="card-content">
                    <!-- <div class="topics">
                      <span v-for="(topic, index) in card.selectTopics">{{ topic }}{{ index == card.selectTopics.length - 1 ? '' : ', ' }}</span>
                    </div> -->
                    <div class="ages">
                      <span v-html="card.ageRange"></span>
                    </div>
                    <div v-if="card.skillLevel" :class="'skill-level'">{{ card.skillLevel }}</div>
                  </article>
                </a>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </div>
      
    </div>
  </div>
</template>

<style lang="scss">
.card-product {
  .card-product-container {
    display: block;
    text-decoration: none;
    color: $color-body-text;
    height: 100%;
    width: 130px;
    overflow: hidden;
    border-radius: 24px;
    // border: solid 1px $color-light-gray;
    padding: 10px 5px 40px;
    
    transition: box-shadow 0.3s;
    background: $color-secondary;
    &.no-gallery{
      height: auto;
      &:not(:last-child){
        margin-right: 20px;
      }
    }
    &:hover{
      font-weight: 400;
    }

    .card-image {
      max-width: 100%;
      // height: 250px;
      margin: 0 auto;
      display: block;
      border-radius: 12px;
    }

    .card-details{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      .product-badge{
        font-size: .875rem;
        padding: 2px 4px;
        border-radius: 4px;
      }
    }

    .card-title,
    .card-content{
      display: block;
      margin-top: 10px;
    }

    .card-title {
      font-size: 1rem;
      font-weight: bold;
      color: $color-labels;
    }

    .card-content {
      font-size: $font-size-base;
      color: $color-subtext;
    }
  }

  @include media-breakpoint-up(lg) {
    .card-product-container{
      display: block;
      text-decoration: none;
      color: $color-body-text;
      height: 100%;
      width: 242px;
      overflow: hidden;
      border-radius: 24px;
      border: solid 1px $color-light-gray;
      padding: 10px 10px 40px;
      
      transition: box-shadow 0.3s;
      background: $color-secondary;
      &:hover {
        -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
      }
    }
  }
}

// Adjustment for slide contents on this component
.splide__arrow--next{
  right: -1rem;
}
.splide__arrow--prev{
  left: -1rem;
}
.slide_item{
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  text-align: left;
}
</style>